import { 
  FIRST_TOKEN, FIRST_TOKEN_CODE, FIRST_TOKEN_EXPIRES, STORE_FIRST_TOKEN, STORE_FIRST_TOKEN_RESULTS, STORE_FIRST_CODE,
  SECOND_TOKEN, SECOND_TOKEN_CODE, SECOND_TOKEN_EXPIRES, STORE_SECOND_TOKEN, STORE_SECOND_CODE, STORE_SECOND_TOKEN_RESULTS,
  THIRD_TOKEN, THIRD_TOKEN_CODE, THIRD_TOKEN_EXPIRES, STORE_THIRD_TOKEN, STORE_THIRD_CODE, STORE_THIRD_TOKEN_RESULTS,
  SHOW_MESSAGE, HIDE_MESSAGE, STORE_RESULTS } from '../constants/Adsense'
import { SIGNOUT_SUCCESS } from '../constants/Auth'

const initState = {
  loading: false,
  message: '',
  showMessage: false,
  first_token: localStorage.getItem(FIRST_TOKEN),
  first_token_code: localStorage.getItem(FIRST_TOKEN_CODE),
  first_token_expires: localStorage.getItem(FIRST_TOKEN_EXPIRES),
  first_token_results: {},
  second_token: localStorage.getItem(SECOND_TOKEN),
  second_token_code: localStorage.getItem(SECOND_TOKEN_CODE),
  second_token_expires: localStorage.getItem(SECOND_TOKEN_EXPIRES),
  second_token_results: {},
  third_token: localStorage.getItem(THIRD_TOKEN),
  third_token_code: localStorage.getItem(THIRD_TOKEN_CODE),
  third_token_expires: localStorage.getItem(THIRD_TOKEN_EXPIRES),
  third_token_results: {},
  results: []
}

const adsense = (state = initState, action) => {
  switch (action.type) {
    case SHOW_MESSAGE:
			return {
				...state,
				message: action.message,
				showMessage: true,
				loading: false
			}

		case HIDE_MESSAGE:
			return {
				...state,
				message: '',
				showMessage: false,
			}

    case STORE_FIRST_CODE:
      return {
        ...state,
        loading: false,
        first_token_code: action.code,
      }
    
    case STORE_FIRST_TOKEN:
      return {
        ...state,
				loading: false,
        first_token: action.access_token,
        first_token_expires: action.expiry_date
      }

    case STORE_FIRST_TOKEN_RESULTS:
      return {
        ...state,
        first_token_results: action.first_token_results,
      }

    case STORE_SECOND_TOKEN_RESULTS:
      return {
        ...state,
        second_token_results: action.second_token_results,
      }
    case STORE_THIRD_TOKEN_RESULTS:
      return {
        ...state,
        third_token_results: action.third_token_results
      }

    case STORE_SECOND_TOKEN:
      return {
        ...state,
        loading: false,
        second_token: action.access_token,
        second_token_expires: action.expiry_date
      }

    case STORE_SECOND_CODE:
      return {
        ...state,
        loading: false,
        second_token_code: action.code
      }
    
    case STORE_THIRD_TOKEN:
      return {
        ...state,
        loading: false,
        third_token: action.access_token,
        third_token_expires: action.expiry_date
      }

    case STORE_THIRD_CODE:
      return {
        ...state,
        loading: false,
        third_token_code: action.code
      }
    
    case STORE_RESULTS:
      return {
        ...state,
        loading: false,
        results: action.adsense
      }
    
    case SIGNOUT_SUCCESS: {
      return {
        ...state,
        first_token_code: null,
        first_token: null,
        first_token_expires: null,
        first_token_results: {},
        second_token_code: null,
        second_token: null,
        second_token_expires: null,
        second_token_results: {},
        third_token_code: null,
        third_token: null,
        third_token_expires: null,
        third_token_results: {},
        results: []
      }
    }
  
    default:
      return state
  }

}

export default adsense