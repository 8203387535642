import {
	STORED,
  SHOW_LOADING,
  SHOW_MESSAGE,
  HIDE_MESSAGE,
  CAMPAING_SUMMARY_STORED,
  CAMPAING_REVENUE_SUMMARY_STORED,
  SHOW_RS,
  SHOW_CS,
  SHOW_DASHBOARD_GRAPH,
  DASHBOARD_SUMMARY_STORED
} from '../constants/Taboola'

const initState = {
  accounts_id: [],
	ad_revenue: 0,
  ad_cpc: 0,
  ad_rpm: 0,
  loading: false,
  message: '',
  showMessage: false,
  results: [],
  clicks: 0,
  impressions: 0,
  campaigns_num: 0,
  visible_impressions: 0,
  chart_data: { series: [], categories: [] },
  show_rs: localStorage.getItem(SHOW_RS),
  show_cs: localStorage.getItem(SHOW_CS),
  spent: 0,
  campaigns: [],
  countries: [],
  platforms: [],
  platforms_summary: [],
  campaings_summary: {},
  campaings_summary_results: [],
  campaings_providers_results: [],
  revenues_summary: {},
  revenues_summary_results: [],
  revenues_totals: [],
  revenue_accounts_id: [],
  countries_summary: []
}

const taboola = (state = initState, action) => {
	switch (action.type) {
		case STORED:
			return {
				...state,
				loading: false
			}
    case CAMPAING_SUMMARY_STORED:
      return {
        ...state,
				loading: false,
        results: action.results,
        clicks: action.clicks,
        impressions: action.impressions,
        campaigns_num: action.campaigns_num,
        visible_impressions: action.visible_impressions,
        chart_data: action.chart_data,
        accounts_id: action.accounts_id,
        spent: action.spent
      }
    case CAMPAING_REVENUE_SUMMARY_STORED:
      return {
        ...state,
        loading: false,
        results: action.results,
        clicks: action.clicks,
        ad_revenue: action.ad_revenue,
        ad_cpc: action.ad_cpc,
        ad_rpm: action.ad_rpm,
        chart_data: action.chart_data,
        accounts_id: action.accounts_id
      }
    case DASHBOARD_SUMMARY_STORED:
      return {
        ...state,
        loading: false,
        chart_data: action.chart_data,
        countries: action.countries,
        campaings_summary: action.campaings_summary,
        campaings_summary_results: action.campaings_summary_results,
        revenues_summary: action.revenues_summary,
        revenues_summary_results: action.revenues_summary_results,
        platforms: action.platforms,
        campaings_providers_results: action.campaings_providers_results,
        revenues_totals: action.revenues_totals,
        accounts_id: action.campaing_accounts_id,
        platforms_summary: action.platforms_summary,
        revenue_accounts_id: action.revenue_accounts_id,
        countries_summary: action.countries_summary
      }
		case SHOW_MESSAGE: 
			return {
				...state,
				message: action.message,
				showMessage: true,
				loading: false
			}
		case HIDE_MESSAGE: 
			return {
				...state,
				message: '',
				showMessage: false,
			}
		case SHOW_LOADING: {
			return {
				...state,
				loading: true
			}
		}
    case SHOW_DASHBOARD_GRAPH:
      return {
        ...state,
        show_rs: action.show_rs,
        show_cs: action.show_cs
      }
		default:
			return state;
	}
}

export default taboola