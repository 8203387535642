import {
  CAMPAING_SUMMARY,
  CAMPAING_REVENUE_SUMMARY,
  SHOW_LOADING,
  SHOW_MESSAGE,
  CAMPAING_SUMMARY_STORED,
  CAMPAING_REVENUE_SUMMARY_STORED,
  SHOW_DASHBOARD_GRAPH,
  DASHBOARD_SUMMARY,
  DASHBOARD_SUMMARY_STORED
} from '../constants/Taboola'

export const dashboardSummary = (data) => {
  return {
    type: DASHBOARD_SUMMARY,
    payload: data
  }
}

export const dashboardSummaryStored = ({ campaing_accounts_id, chart_data, countries, countries_summary, campaings_summary, campaings_summary_results, revenues_summary, revenues_summary_results, platforms, platforms_summary, campaings_providers_results, revenues_totals, revenue_accounts_id }) => {
  return {
    type: DASHBOARD_SUMMARY_STORED,
    chart_data, countries, campaings_summary, campaings_summary_results, revenues_summary, revenues_summary_results, platforms, campaings_providers_results,
    revenues_totals, campaing_accounts_id, platforms_summary, revenue_accounts_id, countries_summary
  }
}

export const campaingSummary = (data) => {
  return {
    type: CAMPAING_SUMMARY,
    payload: data
  }
}

export const campaingRevenueSummary = (data) => {
  return {
    type: CAMPAING_REVENUE_SUMMARY,
    payload: data
  }
}

export const campaingSummaryResults = ({ chart_data, campaigns_num, clicks, impressions, results, visible_impressions, accounts_id, spent }) => {
  return {
    type: CAMPAING_SUMMARY_STORED,
    chart_data,
    campaigns_num,
    clicks,
    impressions,
    results,
    visible_impressions,
    accounts_id,
    spent
  }
}

export const campaingRevenueSummaryResults = ({ chart_data, ad_revenue, clicks, ad_rpm, results, ad_cpc, accounts_id }) => {
  return {
    type: CAMPAING_REVENUE_SUMMARY_STORED,
    chart_data,
    ad_revenue,
    clicks,
    ad_rpm,
    results,
    ad_cpc,
    accounts_id
  }
}

export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  }
}

export const showMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    message
  }
}

export const showDashboardGraph = ({ show_rs, show_cs }) => {
  return {
    type: SHOW_DASHBOARD_GRAPH,
    show_rs, show_cs
  }
}
