import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
  CAMPAING_SUMMARY,
  CAMPAING_REVENUE_SUMMARY,
  DASHBOARD_SUMMARY,
  // RESULTS
} from '../constants/Taboola';
import {
	showMessage,
  campaingSummaryResults,
  campaingRevenueSummaryResults,
  dashboardSummaryStored
} from '../actions/Taboola'
import { storeAdsenseResults } from '../actions/Adsense'
import GenericService from 'services/GenericService'

export function* campaingSummary() {
  yield takeEvery(CAMPAING_SUMMARY, function* ({ payload }) {
		try {
			const { success, results, chart_data, campaigns_num, clicks, impressions, visible_impressions, accounts_id, spent, error } = yield call(GenericService.getPost, { url: `taboola/summary/${payload.account_id}/${payload.date_ini}/${payload.date_end}`, method: 'get', data: payload })
			if (!success) {
				yield put(showMessage(error))
			} else {
				yield put(campaingSummaryResults({ chart_data, campaigns_num, clicks, impressions, results, visible_impressions, accounts_id, spent }))
			}
		} catch (err) {
      yield put(showMessage(err))
      console.log('error >', err)
		}
	})
}

export function* revenueSummary() {
  yield takeEvery(CAMPAING_REVENUE_SUMMARY, function* ({ payload }) {
		try {
			const { success, results, chart_data, ad_revenue, clicks, ad_rpm, ad_cpc, accounts_id, error } = yield call(GenericService.getPost, { url: `taboola/revenue_summary/${payload.account_id}/${payload.date_ini}/${payload.date_end}`, method: 'get', data: payload })
			if (!success) {
				yield put(showMessage(error))
			} else {
				yield put(campaingRevenueSummaryResults({ chart_data, ad_revenue, clicks, ad_rpm, results, ad_cpc, accounts_id }))
			}
		} catch (err) {
      yield put(showMessage(err))
      console.log('error >', err)
		}
	})
}

export function* dashboardSummary() {
  yield takeEvery(DASHBOARD_SUMMARY, function* ({ payload }) {
		try {
      const dashboard = yield call(GenericService.getPost, { url: `taboola/dashboard`, method: 'post', data: payload })
			if (!dashboard.success) {
				yield put(showMessage(dashboard.error))
			} else {
				yield put(dashboardSummaryStored({ ...dashboard }))
				if(dashboard.adsense && dashboard.adsense.length) yield put(storeAdsenseResults({ adsense: dashboard.adsense }))
			}
		} catch (err) {
      yield put(showMessage(err))
      console.log('error >', err)
		}
	})
}

export default function* rootSaga() {
  yield all([
		fork(campaingSummary),
    fork(revenueSummary),
    fork(dashboardSummary)
  ])
}
