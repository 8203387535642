import {
	AUTH_TOKEN,
  REVENUE_TOKEN,
  CAMPAING_TOKEN,
	AUTHENTICATED,
  ACCOUNT_ID,
  EXPIRES_IN,
	SHOW_AUTH_MESSAGE,
	HIDE_AUTH_MESSAGE,
  NAME,
	SIGNOUT_SUCCESS,
	SIGNUP_SUCCESS,
	SHOW_LOADING,
	SIGNIN_WITH_GOOGLE_AUTHENTICATED,
  SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
  CHANGE_USER_SUCCESS
} from '../constants/Auth';

const initState = {
  loading: false,
  message: '',
  showMessage: false,
  redirect: '',
  token: localStorage.getItem(AUTH_TOKEN),
  revenue_token: localStorage.getItem(REVENUE_TOKEN),
  campaing_token: localStorage.getItem(CAMPAING_TOKEN),
  name: localStorage.getItem(NAME),
  account_id: localStorage.getItem(ACCOUNT_ID),
  expires_in: localStorage.getItem(EXPIRES_IN),
  currency: 'EUR',
  main_accounts: []
}

const auth = (state = initState, action) => {
	switch (action.type) {
		case AUTHENTICATED:
			return {
				...state,
				loading: false,
				redirect: '/',
				token: action.token,
        revenue_token: action.revenue_token,
        campaing_token: action.campaing_token,
				name: action.name,
				account_id: action.account_id,
        expires_in: action.expires_in,
        currency: action.currency,
        main_accounts: action.main_accounts
			}
		case SHOW_AUTH_MESSAGE: 
			return {
				...state,
				message: action.message,
				showMessage: true,
				loading: false
			}
		case HIDE_AUTH_MESSAGE: 
			return {
				...state,
				message: '',
				showMessage: false,
			}
		case SIGNOUT_SUCCESS: {
			return {
				...state,
				token: null,
				redirect: '/',
				loading: false,
        currency: 'EUR',
        main_accounts: []
			}
		}
		case SIGNUP_SUCCESS: {
			return {
			  ...state,
			  loading: false,
			  token: action.token
			}
		}
		case SHOW_LOADING: {
			return {
				...state,
				loading: true
			}
		}
		case SIGNIN_WITH_GOOGLE_AUTHENTICATED: {
			return {
				...state,
				loading: false,
				token: action.token
			}
		}
		case SIGNIN_WITH_FACEBOOK_AUTHENTICATED: {
			return {
				...state,
				loading: false,
				token: action.token
			}
		}
    case CHANGE_USER_SUCCESS: {
			return {
				...state,
				loading: false,
				redirect: '/',
				token: action.token,
				name: action.name,
				account_id: action.account_id,
        expires_in: action.expires_in,
        currency: action.currency,
        main_accounts: action.main_accounts
			}
		}
		default:
			return state;
	}
}

export default auth