import { combineReducers } from 'redux'
import Auth from './Auth'
import Theme from './Theme'
import Taboola from './Taboola'
import Adsense from './Adsense'

const reducers = combineReducers({
    theme: Theme,
    auth: Auth,
    taboola: Taboola,
    adsense: Adsense
})

export default reducers