export const STORED = 'STORED'
export const SHOW_LOADING = 'SHOW_LOADING'
export const SHOW_MESSAGE = 'SHOW_MESSAGE'
export const HIDE_MESSAGE = 'HIDE_MESSAGE'
export const DASHBOARD_SUMMARY = 'DASHBOARD_SUMMARY'
export const DASHBOARD_SUMMARY_STORED = 'DASHBOARD_SUMMARY_STORED'
export const CAMPAING_SUMMARY = 'CAMPAING_SUMMARY'
export const CAMPAING_SUMMARY_STORED = 'CAMPAING_SUMMARY_STORED'
export const CAMPAING_REVENUE_SUMMARY = 'CAMPAING_REVENUE_SUMMARY'
export const CAMPAING_REVENUE_SUMMARY_STORED = 'CAMPAING_REVENUE_SUMMARY_STORED'
export const SHOW_RS = 'show_rs'
export const SHOW_CS = 'show_cs'
export const SHOW_DASHBOARD_GRAPH = 'SHOW_DASHBOARD_GRAPH'