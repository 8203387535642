export const SHOW_LOADING = 'SHOW_LOADING'
export const HIDE_LOADING = 'HIDE_LOADING'
export const SHOW_MESSAGE = 'SHOW_MESSAGE'
export const HIDE_MESSAGE = 'HIDE_MESSAGE'
export const GENERATE_TOKEN = 'GENERATE_TOKEN'
export const GENERATE_CODE = 'GENERATE_CODE'
export const STORE_TOKEN_CODE = 'STORE_TOKEN_CODE'
export const STORE_FIRST_TOKEN = 'STORE_FIRST_TOKEN'
export const CHECK_TOKEN = 'CHECK_TOKEN'
export const STORE_FIRST_CODE = 'STORE_FIRST_CODE'
export const STORE_FIRST_TOKEN_RESULTS = 'STORE_FIRST_TOKEN_RESULTS'
export const FIRST_TOKEN = 'FIRST_TOKEN'
export const FIRST_TOKEN_CODE = 'FIRST_TOKEN_CODE'
export const FIRST_TOKEN_EXPIRES = 'FIRST_TOKEN_EXPIRES'
export const STORE_SECOND_TOKEN = 'STORE_SECOND_TOKEN'
export const STORE_SECOND_CODE = 'STORE_SECOND_CODE'
export const STORE_SECOND_TOKEN_RESULTS = 'STORE_SECOND_TOKEN_RESULTS'
export const SECOND_TOKEN = 'SECOND_TOKEN'
export const SECOND_TOKEN_CODE = 'SECOND_TOKEN_CODE'
export const SECOND_TOKEN_EXPIRES = 'SECOND_TOKEN_EXPIRES'
export const STORE_THIRD_TOKEN = 'STORE_THIRD_TOKEN'
export const STORE_THIRD_CODE = 'STORE_THIRD_CODE'
export const STORE_THIRD_TOKEN_RESULTS = 'STORE_THIRD_TOKEN_RESULTS'
export const THIRD_TOKEN = 'THIRD_TOKEN'
export const THIRD_TOKEN_CODE = 'THIRD_TOKEN_CODE'
export const THIRD_TOKEN_EXPIRES = 'THIRD_TOKEN_EXPIRES'
export const STORE_CODE = 'STORE_CODE'
export const STORE_RESULTS = 'STORE_RESULTS'