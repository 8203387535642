const dev = {
  //   API_ENDPOINT_URL: 'https://jsonplaceholder.typicode.com'
  API_ENDPOINT_URL: process.env.REACT_APP_API_URL,
  TABOOLA_DEINFORMACION: process.env.REACT_APP_TABOOLA_DEINFORMACION,
  TABOOLA_SINOTICIA: process.env.REACT_APP_TABOOLA_SINOTICIA
}

const prod = {
  //   API_ENDPOINT_URL: 'https://api.prod.com'
  API_ENDPOINT_URL: process.env.REACT_APP_API_URL,
  TABOOLA_DEINFORMACION: process.env.REACT_APP_TABOOLA_DEINFORMACION,
  TABOOLA_SINOTICIA: process.env.REACT_APP_TABOOLA_SINOTICIA
}

const test = {
  // API_ENDPOINT_URL: 'https://api.test.com'
  API_ENDPOINT_URL: process.env.REACT_APP_API_URL,
  TABOOLA_DEINFORMACION: process.env.REACT_APP_TABOOLA_DEINFORMACION,
  TABOOLA_SINOTICIA: process.env.REACT_APP_TABOOLA_SINOTICIA
}

const getEnv = () => {
  switch (process.env.NODE_ENV) {
    case 'development': return dev
    case 'production': return prod
    case 'test': return test
    default: break;
  }
}

export const env = getEnv()
