import React from "react"
import { Menu, Dropdown, Avatar } from "antd"
import { connect } from 'react-redux'
import { LogoutOutlined, SettingOutlined } from '@ant-design/icons'
import { Link } from "react-router-dom";
import { signOut } from 'redux/actions/Auth'
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import { UserOutlined } from '@ant-design/icons';

export const NavProfile = ({ signOut }) => {
  const profileImg = "/img/avatars/hw-thumb2.png";
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar size={45} src={profileImg} />
          <div className="pl-3">
            <h4 className="mb-0">{'Heroik Ads'}</h4>
            <span className="text-muted">{'Account'}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu defaultSelectedKeys={['0']}>
          <Menu.Item key={1}>
            <span>
              <SettingOutlined className="mr-3"/>
              <Link to={`${APP_PREFIX_PATH}/dashboards/settings`} >Settings</Link>
              {/* <span className="font-weight-normal">Settings</span> */}
            </span>
          </Menu.Item>
          <Menu.Item key={2} onClick={e => signOut()}>
            <span>
              <LogoutOutlined className="mr-3"/>
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  )
  
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item>
          <UserOutlined />
        </Menu.Item>
      </Menu>
    </Dropdown>
  )
}

const mapStateToProps = ({ auth }) => {
  return { }
}

const mapDispatchToProps = { signOut }

export default connect(mapStateToProps, mapDispatchToProps)(NavProfile)
