import { all } from 'redux-saga/effects';
import Auth from './Auth';
import Taboola from './Taboola';
import Adsense from './Adsense';

export default function* rootSaga(getState) {
  yield all([
    Auth(),
    Taboola(),
    Adsense()
  ]);
}
