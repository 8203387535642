import fetch from 'auth/FetchInterceptor'
const GenericService = {}

GenericService.getPost = function ({url, method = 'get', data}) {
  return fetch({
    url: `${process.env.REACT_APP_API_URL}/${url}`,
    method,
    data
  })
}

GenericService.setPost = function (data) {
  return fetch({
    url: '/posts',
    method: 'post',
    data: data
  })
}

export default GenericService