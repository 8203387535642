import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
	AUTH_TOKEN,
  CAMPAING_TOKEN,
  REVENUE_TOKEN,
  ACCOUNT_ID,
  EXPIRES_IN,
  NAME,
	SIGNIN,
	SIGNOUT,
	SIGNUP,
	SIGNIN_WITH_GOOGLE,
	SIGNIN_WITH_FACEBOOK,
  CHANGE_USER
} from '../constants/Auth'
import {
	FIRST_TOKEN, FIRST_TOKEN_CODE, FIRST_TOKEN_EXPIRES,
	SECOND_TOKEN, SECOND_TOKEN_CODE, SECOND_TOKEN_EXPIRES,
	THIRD_TOKEN, THIRD_TOKEN_CODE, THIRD_TOKEN_EXPIRES
} from '../constants/Adsense'
import {
	showAuthMessage,
	authenticated,
	signOutSuccess,
	signUpSuccess,
	signInWithGoogleAuthenticated,
	signInWithFacebookAuthenticated
} from "../actions/Auth";
import { SHOW_RS, SHOW_CS } from '../constants/Taboola'
// import { showDashboardGraph } from '../actions/Taboola'

import FirebaseService from 'services/FirebaseService'
import JwtAuthService from 'services/JwtAuthService'

export function* signInWithFBEmail() {
  yield takeEvery(SIGNIN, function* ({payload}) {
		try {
			const { access_token, revenue_token, campaing_token, account_id, name, success, main_accounts, error } = yield call(JwtAuthService.login, payload)
			if (!success)
				yield put(showAuthMessage(error))
			else
				localStorage.setItem(AUTH_TOKEN, access_token)
        localStorage.setItem(REVENUE_TOKEN, revenue_token)
        localStorage.setItem(CAMPAING_TOKEN, campaing_token)
        localStorage.setItem(ACCOUNT_ID, account_id)
				yield put(authenticated({ token: access_token, account_id, name, main_accounts, revenue_token, campaing_token }))
		} catch (err) {
			yield put(showAuthMessage(err))
		}
	})
}

export function* signOut() {
  yield takeEvery(SIGNOUT, function* () {
		try {
			// const signOutUser = yield call(FirebaseService.signOutRequest)
			// if (signOutUser === undefined) {
			// 	localStorage.removeItem(AUTH_TOKEN);
			// 	yield put(signOutSuccess(signOutUser));
			// } else {
			// 	yield put(showAuthMessage(signOutUser.message));
			// }
      localStorage.removeItem(AUTH_TOKEN)
			localStorage.removeItem(FIRST_TOKEN)
			localStorage.removeItem(FIRST_TOKEN_CODE)
			localStorage.removeItem(FIRST_TOKEN_EXPIRES)
			localStorage.removeItem(SECOND_TOKEN)
			localStorage.removeItem(SECOND_TOKEN_CODE)
			localStorage.removeItem(SECOND_TOKEN_EXPIRES)
			localStorage.removeItem(THIRD_TOKEN)
			localStorage.removeItem(THIRD_TOKEN_CODE)
			localStorage.removeItem(THIRD_TOKEN_EXPIRES)
			yield put(signOutSuccess('Sign Out'))
		} catch (err) {
			yield put(showAuthMessage(err));
		}
	});
}

export function* signUpWithFBEmail() {
  yield takeEvery(SIGNUP, function* ({payload}) {
		const {email, password} = payload;
		try {
			const user = yield call(FirebaseService.signUpEmailRequest, email, password);
			if (user.message) {
				yield put(showAuthMessage(user.message));
			} else {
				localStorage.setItem(AUTH_TOKEN, user.user.uid);
				yield put(signUpSuccess(user.user.uid));
			}
		} catch (error) {
			yield put(showAuthMessage(error));
		}
	}
	);
}

export function* signInWithFBGoogle() {
  yield takeEvery(SIGNIN_WITH_GOOGLE, function* () {
		try {
			const user = yield call(FirebaseService.signInGoogleRequest);
			if (user.message) {
				yield put(showAuthMessage(user.message));
			} else {
				localStorage.setItem(AUTH_TOKEN, user.user.uid);
				yield put(signInWithGoogleAuthenticated(user.user.uid));
			}
		} catch (error) {
			yield put(showAuthMessage(error));
		}
	});
}

export function* signInWithFacebook() {
  yield takeEvery(SIGNIN_WITH_FACEBOOK, function* () {
		try {
			const user = yield call(FirebaseService.signInFacebookRequest);
			if (user.message) {
				yield put(showAuthMessage(user.message));
			} else {
				localStorage.setItem(AUTH_TOKEN, user.user.uid);
				yield put(signInWithFacebookAuthenticated(user.user.uid));
			}
		} catch (error) {
			yield put(showAuthMessage(error));
		}
	});
}

export function* changeUser() {
  yield takeEvery(CHANGE_USER, function* ({payload}) {
		try {
      const { show_rs, show_cs } = payload
			const { access_token, account_id, currency, expires_in, name, success, main_accounts, error } = yield call(JwtAuthService.changeUser, payload)
			if (!success) {
				yield put(showAuthMessage(error))
			} else {
				localStorage.setItem(AUTH_TOKEN, access_token)
        localStorage.setItem(NAME, name)
        localStorage.setItem(ACCOUNT_ID, account_id)
        localStorage.setItem(EXPIRES_IN, expires_in)
        localStorage.setItem(SHOW_RS, show_rs)
        localStorage.setItem(SHOW_CS, show_cs)
        // yield put(showDashboardGraph({ show_rs, show_cs }))
				yield put(authenticated({ token: access_token, account_id, expires_in, currency, name, main_accounts }))
			}
		} catch (err) {
			yield put(showAuthMessage(err))
		}
	})
}

export default function* rootSaga() {
  yield all([
		fork(signInWithFBEmail),
		fork(signOut),
		fork(signUpWithFBEmail),
		fork(signInWithFBGoogle),
		fork(signInWithFacebook),
    fork(changeUser)
  ]);
}
