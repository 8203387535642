import {
  SHOW_LOADING,
  SHOW_MESSAGE,
  STORE_FIRST_TOKEN,
  GENERATE_TOKEN,
  GENERATE_CODE,
  CHECK_TOKEN,
  STORE_FIRST_TOKEN_RESULTS,
  STORE_CODE,
  STORE_FIRST_CODE,
  STORE_SECOND_CODE,
  STORE_THIRD_CODE,
  STORE_SECOND_TOKEN,
  STORE_THIRD_TOKEN,
  STORE_SECOND_TOKEN_RESULTS,
  STORE_THIRD_TOKEN_RESULTS,
  STORE_RESULTS
} from '../constants/Adsense'

export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  }
}

export const showMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    message
  }
}

export const generateToken = (data) => {
  return {
    type: GENERATE_TOKEN,
    payload: data
  }
}

export const generateCode = (data) => {
  return {
    type: GENERATE_CODE,
    payload: data
  }
}

export const storeCode = (data) => {
  return {
    type: STORE_CODE,
    payload: data
  }
}

export const storeFirstCode = (data) => {
  return {
    type: STORE_FIRST_CODE,
    ...data
  }
}

export const storeSecondCode = (data) => {
  return {
    type: STORE_SECOND_CODE,
    ...data
  }
}

export const storeThirdCode = (data) => {
  return {
    type: STORE_THIRD_CODE,
    ...data
  }
}

export const storeFirstToken = (data) => {
  return {
    type: STORE_FIRST_TOKEN,
    ...data
  }
}

export const storeSecondToken = (data) => {
  return {
    type: STORE_SECOND_TOKEN,
    ...data
  }
}

export const storeThirdToken = (data) => {
  return {
    type: STORE_THIRD_TOKEN,
    ...data
  }
}

export const storeFirstTokenResults = (data) => {
  return {
    type: STORE_FIRST_TOKEN_RESULTS,
    ...data
  }
}

export const storeSecondTokenResults = (data) => {
  return {
    type: STORE_SECOND_TOKEN_RESULTS,
    ...data
  }
}

export const storeThirdTokenResults = (data) => {
  return {
    type: STORE_THIRD_TOKEN_RESULTS,
    ...data
  }
}

export const checkToken = (data) => {
  return {
    type: CHECK_TOKEN,
    payload: data
  }
}

export const storeAdsenseResults = (data) => {
  return {
    type: STORE_RESULTS,
    ...data
  }
}