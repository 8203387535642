import { all, takeEvery, put, fork, call, take } from 'redux-saga/effects';
import {
  CHECK_TOKEN,
  GENERATE_TOKEN,
  GENERATE_CODE,
  FIRST_TOKEN,
  FIRST_TOKEN_CODE,
  FIRST_TOKEN_EXPIRES,
  SECOND_TOKEN,
  SECOND_TOKEN_CODE,
  SECOND_TOKEN_EXPIRES,
  THIRD_TOKEN,
  THIRD_TOKEN_CODE,
  THIRD_TOKEN_EXPIRES,
  STORE_CODE,
} from '../constants/Adsense';
import {
	showMessage,
  storeFirstCode,
  storeSecondCode,
  storeThirdCode,
  storeFirstToken,
  storeSecondToken,
  storeThirdToken,
  storeFirstTokenResults,
  storeSecondTokenResults,
  storeThirdTokenResults
} from '../actions/Adsense'
import GenericService from 'services/GenericService'

export function* generateToken() {
  yield takeEvery(GENERATE_TOKEN, function* ({ payload }) {
		try {
      // console.log('***adsense > generateToken', payload)
      const { code } = payload
      const { success, data: { access_token, expiry_date }, type } = yield call(GenericService.getPost, { url: `adsense/generateToken`, method: 'post', data: payload })

			if (!success) {
				yield put(showMessage('Error'))
			} else {
        
        switch (type) {
          case 'first':
            localStorage.setItem(FIRST_TOKEN, access_token)
            localStorage.setItem(FIRST_TOKEN_EXPIRES, expiry_date)
            yield put(storeFirstToken({ access_token, expiry_date, code }))    
            break;
          case 'second':
            localStorage.setItem(SECOND_TOKEN, access_token)
            localStorage.setItem(SECOND_TOKEN_EXPIRES, expiry_date)
            yield put(storeSecondToken({ access_token, expiry_date, code }))    
            break;
          case 'third':
            localStorage.setItem(THIRD_TOKEN, access_token)
            localStorage.setItem(THIRD_TOKEN_EXPIRES, expiry_date)
            yield put(storeThirdToken({ access_token, expiry_date, code }))    
            break;
        
          default:
            break;
        }
			}
		} catch (err) {
      yield put(showMessage(err))
      console.log('error >', err)
		}
	})
}

export function* generateCode(){
  yield takeEvery(GENERATE_CODE, function* ({ payload }) {
    try {
      let urlType = ''
      switch (payload.type) {
        case 1:
          localStorage.setItem(FIRST_TOKEN, '')
          localStorage.setItem(FIRST_TOKEN_CODE, '')
          localStorage.setItem(FIRST_TOKEN_EXPIRES, '')
          urlType = 'first'
        break;
        case 2:
          localStorage.setItem(SECOND_TOKEN, '')
          localStorage.setItem(SECOND_TOKEN_CODE, '')
          localStorage.setItem(SECOND_TOKEN_EXPIRES, '')
          urlType = 'second'
        break;
        case 3:
          localStorage.setItem(THIRD_TOKEN, '')
          localStorage.setItem(THIRD_TOKEN_CODE, '')
          localStorage.setItem(THIRD_TOKEN_EXPIRES, '')
          urlType = 'third'
        break;
      
        default:
          break;
      }
      payload.history.push(`/app/dashboards/getUrl/${urlType}`)
    } catch (err) {
      yield put(showMessage(err))
      console.log('error >', err)
    }
  })
}

export function* storeCode() {
  yield takeEvery(STORE_CODE, function* ({ payload }) {
    try {
      switch (payload.type) {
        case 1:
          localStorage.setItem(FIRST_TOKEN_CODE, payload.code)
          yield put(storeFirstCode({ code: payload.code }))
        break;
        case 2:
          localStorage.setItem(SECOND_TOKEN_CODE, payload.code)
          yield put(storeSecondCode({ code: payload.code }))
        break;
        case 3:
          localStorage.setItem(THIRD_TOKEN_CODE, payload.code)
          yield put(storeThirdCode({ code: payload.code }))
        break;
      
        default:
          break;
      }
    } catch (err) {
      yield put(showMessage(err))
      console.log('error >', err)
    }
  })
}

export function* checkToken(){
  yield takeEvery(CHECK_TOKEN, function* ({ payload }) {
    try {
      // console.log('***Sagas > checkToken', payload)
      const { success, data, type } = yield call(GenericService.getPost, { url: `adsense/checkToken`, method: 'post', data: payload })
      if (!success) {
				yield put(showMessage('Error'))
			} else {
        switch (type) {
          case 1:
            yield put(storeFirstTokenResults({ first_token_results: data }))    
            break;
          
          case 2:
            yield put(storeSecondTokenResults({ second_token_results: data }))    
            break;
          
          case 3:
            yield put(storeThirdTokenResults({ third_token_results: data }))    
          break;
        
          default:
            break;
        }
			}
    } catch (err) {
      yield put(showMessage(err))
      console.log('error >', err)
    }
  })
}

export function* logActions() {
  while (true) {
    const action = yield take('*')
    // eslint-disable-next-line no-restricted-syntax
    console.log('logActions >', action.type)
  }
}

export default function* rootSaga() {
  yield all([
    fork(generateToken),
    fork(logActions),
    fork(generateCode),
    fork(checkToken),
    fork(storeCode)
  ])
}
