import fetch from 'auth/FetchInterceptor'
const JwtAuthService = {}

JwtAuthService.login = function (data) {
	return fetch({
		url: `${process.env.REACT_APP_API_URL}/user/login`,
		method: 'post',
		headers: {
      'public-request': 'true'
    },
		data: data
	})
}
// JwtAuthService.signUp = function (data) {
// 	return fetch({
// 		url: '/auth/signup',
// 		method: 'post',
// 		headers: {
//       'public-request': 'true'
//     },
// 		data: data
// 	})
// }

JwtAuthService.changeUser = function (data) {
	return fetch({
		url: `${process.env.REACT_APP_API_URL}/user/change`,
		method: 'post',
		headers: {
      'public-request': 'true'
    },
		data: data
	})
}

export default JwtAuthService